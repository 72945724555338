import { EventBusModule } from "@vlinder-web/event-bus-module-react";
import { FSMFactoryModule } from "@vlinder-web/state-machine-factory-module-react";
import { GraphqlServerModule } from "@vlinder-web/graphql-server-module-react";
import { StorageModule } from "@vlinder-web/storage-module-react";
import { BaseApplication } from "./base/baseApplication";
import { WalletconnectModule } from "@vlinder-web/walletconnect-module-react";
import { UnitTest } from "./unit.test";

import {
    TrafficLightService,
    DeviceInfoService,
    DummyRestService,
    DummyGraphQLService,
    OrganizationService,
    AssetsService,
    AssetDescriptionService,
    CreatedAssetTableService,
    AssetDetailService,
    ListedAssetDescriptionService,
    ListedAssetsService,
    ListedAssetDetailService,
    ListedAssetTableService,
    SoldAssetDescriptionService,
    SoldAssetsService,
    SoldAssetDetailService,
    SoldAssetTableService,
    CreateAssetService,
    CreateAssetDescriptionService,
    AuthService,
    StoresService,
    SoldStoresService,
    ProfileService,
    WalletService,
    EditPriceService,
    ClaimAssetsService,
    ClaimAssetDetailService,
    EnableClaimService,
    ClaimAssetTableService,
    ClaimDescriptionService,
    ProcessingFeeService,
    MyWalletService,
    WalletApiService,
    MyProfileService,
    OwnedAssetDescriptionService,
    OwnedAssetTableService,
    OwnedAssetsService,
    OwnedAssetDetailService,
    usersTableService,
    OnboardMinterService,
    ShowcasedAssetService
} from '../services';






export class VApplication extends BaseApplication {
    private static instance: VApplication;

    private constructor(options: any = {}) {
        super(options);

        this.setupBindings();

        //Modules
        this.initModules();

        //Services
        this.initServices();
    }

    static getInstance(options: any = {}) {
        if (!this.instance) {
            this.instance = new VApplication(options);
        }
        return this.instance;
    }

    // @ts-ignore
    async start() {
        await this.initRunLevel();
        await this.unitTest();
    }
    

    setupBindings() {

        const storageService = StorageModule.getInstance();
        const DEFAULT_APP_CONFIG = storageService.get("appConfig", "session");
        const FIAT_ENABLED = storageService.get("fiatEnabled", "session");
        //------------->>>>>--------------//
        // this.configure('services.DummyRestService', {
        //     baseURL: DEFAULT_APP_CONFIG?.servers.rest.dummyRestServer.baseURL,
        // });
        this.configure("services.WalletconnectModule", {
            bridge: DEFAULT_APP_CONFIG ? DEFAULT_APP_CONFIG?.walletConnect?.relayURL : "https://walletbridge.vlinder.io/",
        });
        this.configure("services.AssetsService", {
            imageServer: DEFAULT_APP_CONFIG?.servers?.imageServer,
        });
        this.configure("services.ListedAssetsService", {
            imageServer: DEFAULT_APP_CONFIG?.servers?.imageServer,
        });
      
        this.configure("services.OwnedAssetsService", {
            imageServer: DEFAULT_APP_CONFIG?.servers?.imageServer,
        });
        
        this.configure("services.ClaimAssetsService", {
            imageServer: DEFAULT_APP_CONFIG?.servers?.imageServer,
        });
        this.configure("services.SoldAssetsService", {
            imageServer: DEFAULT_APP_CONFIG?.servers?.imageServer,
        });
        this.configure("services.AssetDetailService", {
            imageServer: DEFAULT_APP_CONFIG?.servers?.imageServer,
            fiatEnabled:FIAT_ENABLED,
            termsURL: DEFAULT_APP_CONFIG?.organization?.termsURL,
        });
        this.configure("services.ListedAssetDetailService", {
            imageServer: DEFAULT_APP_CONFIG?.servers?.imageServer,
            fiatEnabled:FIAT_ENABLED,
        });
        this.configure("services.ClaimAssetDetailService", {
            imageServer: DEFAULT_APP_CONFIG?.servers?.imageServer,
        });
        this.configure("services.SoldAssetDetailService", {
            imageServer: DEFAULT_APP_CONFIG?.servers?.imageServer,
        });
        this.configure("services.OwnedAssetDetailService", {
            imageServer: DEFAULT_APP_CONFIG?.servers?.imageServer,
            termsURL: DEFAULT_APP_CONFIG?.organization?.termsURL,
        });
        this.configure("services.ProfileService", {
            termsURL: DEFAULT_APP_CONFIG?.organization?.termsURL,
        });
        this.configure("services.ShowcasedAssetService", {
            imageServer: DEFAULT_APP_CONFIG?.servers?.imageServer,
        });
        
        //------------->>>>>--------------//
    }


   
    async initRunLevel() {
        const storageSrv = StorageModule.getInstance();
        const DEFAULT_APP_CONFIG = storageSrv.get("appConfig", "session");
        //------------->>>>>--------------//
        const graphqlModule = this.getSync(
            'services.GraphqlServerModule',
        ) as GraphqlServerModule;
        if (graphqlModule) {
            type TClient = typeof DEFAULT_APP_CONFIG.servers.graphql[0];
            DEFAULT_APP_CONFIG?.servers.graphql.forEach(
                async (client: TClient) => {
                    await graphqlModule.createClient(client.name, {
                        url: client.url,
                        getContext: this.getGraphQLContext.bind(this),
                    }).then(res => {
                        console.log(`initRunLevel check`,res);
                       
                      })
                      .catch(err => {
                       console.warn(`error in initRunLevel  ${err.message}`);
                      
                      });
                },
            );
        }
        //------------->>>>>--------------//

        
        const storageService = this.getSync(
            "services.StorageModule"
        ) as StorageModule;
        await storageService.init();
        
    }

    

    async unitTest() {
        const ut = new UnitTest(VApplication);
        ut.run();
    }

    initModules() {
        this.service("services.FSMFactoryModule", FSMFactoryModule);
        this.service("services.EventBusModule", EventBusModule);
        this.service('services.GraphqlServerModule', GraphqlServerModule);
        this.service("services.WalletconnectModule", WalletconnectModule);
        this.service("services.StorageModule", StorageModule);
    }
    

    initServices() {
        this.service('services.AuthService', AuthService);
        this.service('services.TrafficLightService', TrafficLightService);
        //  this.service('services.DummyRestService', DummyRestService);
        this.service('services.DeviceInfoService', DeviceInfoService);
        this.service('services.DummyGraphQLService', DummyGraphQLService);
        this.service('services.OrganizationService', OrganizationService);
        this.service('services.AssetsService', AssetsService);
        this.service('services.AssetDescriptionService', AssetDescriptionService);
        this.service('services.CreatedAssetTableService', CreatedAssetTableService);
        this.service('services.AssetDetailService', AssetDetailService);
        this.service('services.ListedAssetDescriptionService', ListedAssetDescriptionService);
        this.service('services.ListedAssetsService', ListedAssetsService);
        this.service('services.ListedAssetDetailService', ListedAssetDetailService);
        this.service('services.ListedAssetTableService', ListedAssetTableService);
        this.service('services.SoldAssetDescriptionService', SoldAssetDescriptionService);
        this.service('services.SoldAssetsService', SoldAssetsService);
        this.service('services.SoldAssetDetailService', SoldAssetDetailService);
        this.service('services.SoldAssetTableService', SoldAssetTableService);
        this.service('services.CreateAssetDescriptionService', CreateAssetDescriptionService);
        this.service('services.CreateAssetService', CreateAssetService);
        this.service('services.StoresService', StoresService);  
        this.service('services.SoldStoresService', SoldStoresService);  
        this.service('services.ProfileService', ProfileService);  
        this.service('services.WalletService', WalletService); 
        this.service('services.EditPriceService', EditPriceService);
        this.service('services.ClaimAssetsService', ClaimAssetsService);
        this.service('services.ClaimAssetDetailService', ClaimAssetDetailService);
        this.service('services.EnableClaimService', EnableClaimService);
        this.service('services.ClaimAssetTableService', ClaimAssetTableService);
        this.service('services.ClaimDescriptionService', ClaimDescriptionService);
        this.service('services.ProcessingFeeService', ProcessingFeeService);
        this.service('services.MyWalletService', MyWalletService);  
        this.service('services.WalletApiService', WalletApiService);   
        this.service('services.MyProfileService', MyProfileService); 
        this.service('services.OwnedAssetDescriptionService', OwnedAssetDescriptionService); 
        this.service('services.OwnedAssetTableService', OwnedAssetTableService); 
        this.service('services.OwnedAssetsService', OwnedAssetsService); 
        this.service('services.OwnedAssetDetailService', OwnedAssetDetailService); 
        this.service('services.usersTableService', usersTableService);
        this.service('services.OnboardMinterService', OnboardMinterService);
        this.service('services.ShowcasedAssetService', ShowcasedAssetService);
    }

    private getGraphQLContext() {
        const loginService = this.getSync("services.AuthService") as AuthService
        const storageService = this.getSync("services.StorageModule");
        const sessionToken = storageService.get("accessToken", "session")?.value;
        const localToken = storageService.get("accessToken", "local")?.value;

        const DEFAULT_APP_CONFIG = storageService.get("appConfig", "session");
        const deviceInfoService = this.getSync(
            'services.DeviceInfoService',
        ) as DeviceInfoService;
        // console.log("myToken in app index", loginService?.getToken())
        //FIXME:
        let headers = {};
        headers[ DEFAULT_APP_CONFIG?.headers?.headerName] = DEFAULT_APP_CONFIG?.headers?.headerValue;
        return {
            headers: {
                authentication: loginService?.getToken(),
                ...headers,
                // authentication: sessionToken?? localToken,
                headers: {
                    ...{ ...deviceInfoService?.getDeviceInfo() },
                    ...{ ...deviceInfoService?.getAppInfo() },
                },
            },
        };
    }

}
