import gql from "graphql-tag";

export const GET_ORGANIZATION = gql`
query Query {
    getMyOrganization {
      _id
      sameAs
      telephone
      description
      name
      email
      creator
      address {
        addressLine1
        addressLine2
        city
        state
        zipcode
        country
      }
      fullAddress
      logo
      createdAt
      updatedAt
      OrgType
      type
      walletAddress
      chainId
      isWalletRegistered
      supportedChainId
      country
      platform_fee
      brokerage
      paymentOption {
        paymentEnabled
        KYCSubmitted
        provider
        account
      }
      supportedCurrencies
    }
  }
`;

// query Query {
//   getMyOrganization {
//     _id
//     logo
//     name
//     description
//     fullAddress
//     phoneNo
//     website
//     email
//   }
// }
